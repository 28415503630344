define('ember-g-map/components/g-map-route-waypoint', ['exports', 'ember-g-map/templates/components/g-map-route-waypoint', 'ember-g-map/components/g-map-route'], function (exports, _gMapRouteWaypoint, _gMapRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      observer = Ember.observer,
      computed = Ember.computed,
      run = Ember.run,
      assert = Ember.assert;


  var GMapRouteWaypointComponent = Ember.Component.extend({
    layout: _gMapRouteWaypoint.default,
    classNames: ['g-map-route-waypoint'],

    map: computed.alias('routeContext.map'),

    init: function init() {
      this._super.apply(this, arguments);
      if (isEmpty(this.stopover)) {
        this.stopover = true;
      }

      var routeContext = this.get('routeContext');
      assert('Must be inside {{#g-map-route}} component with routeContext set', routeContext instanceof _gMapRoute.default);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateWaypoint();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('routeContext').unregisterWaypoint(this);
    },


    coordsChanged: observer('lat', 'lng', function () {
      run.once(this, 'updateWaypoint');
    }),

    updateWaypoint: function updateWaypoint() {
      var _getProperties = this.getProperties(['lat', 'lng']),
          lat = _getProperties.lat,
          lng = _getProperties.lng;

      if (isPresent(lat) && isPresent(lng) && typeof FastBoot === 'undefined') {
        var location = new google.maps.LatLng(lat, lng);
        this.set('waypoint', {
          location: location,
          stopover: this.get('stopover')
        });
      }
    },


    waypointWasSet: observer('waypoint', function () {
      run.once(this, 'updateRoute');
    }),

    updateRoute: function updateRoute() {
      var routeContext = this.get('routeContext');
      var waypoint = this.get('waypoint');

      if (isPresent(waypoint) && isPresent(routeContext)) {
        routeContext.registerWaypoint(this);
      }
    }
  });

  GMapRouteWaypointComponent.reopenClass({
    positionalParams: ['routeContext']
  });

  exports.default = GMapRouteWaypointComponent;
});