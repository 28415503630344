define('ember-g-map/components/g-map-address-marker', ['exports', 'ember-g-map/templates/components/g-map-address-marker'], function (exports, _gMapAddressMarker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      observer = Ember.observer,
      run = Ember.run,
      isPresent = Ember.isPresent,
      isEmpty = Ember.isEmpty,
      typeOf = Ember.typeOf;


  var GMapAddressMarkerComponent = Ember.Component.extend({
    layout: _gMapAddressMarker.default,
    classNames: ['g-map-address-marker'],

    map: computed.alias('mapContext.map'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initPlacesService();
    },


    mapWasSet: observer('map', function () {
      run.once(this, 'initPlacesService');
    }),

    initPlacesService: function initPlacesService() {
      var map = this.get('map');
      var service = this.get('placesService');

      if (isPresent(map) && isEmpty(service) && typeof FastBoot === 'undefined') {
        service = new google.maps.places.PlacesService(map);
        this.set('placesService', service);
        this.searchLocation();
      }
    },


    onAddressChanged: observer('address', function () {
      run.once(this, 'searchLocation');
    }),

    searchLocation: function searchLocation() {
      var _this = this;

      var service = this.get('placesService');
      var address = this.get('address');

      if (isPresent(service) && isPresent(address) && typeof FastBoot === 'undefined') {
        var request = { query: address };

        service.textSearch(request, function (results, status) {
          if (google && status === google.maps.places.PlacesServiceStatus.OK) {
            _this.updateLocation(results);
          }
        });
      }
    },
    updateLocation: function updateLocation(results) {
      if (!this.destroyed) {
        var lat = results[0].geometry.location.lat();
        var lng = results[0].geometry.location.lng();
        var viewport = results[0].geometry.viewport;


        this.set('lat', lat);
        this.set('lng', lng);
        this.set('viewport', viewport);
        this.sendOnLocationChange(lat, lng, results);
      }
    },
    sendOnLocationChange: function sendOnLocationChange() {
      var onLocationChange = this.attrs.onLocationChange;


      if (typeOf(onLocationChange) === 'function') {
        onLocationChange.apply(undefined, arguments);
      } else {
        this.sendAction.apply(this, ['onLocationChange'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  GMapAddressMarkerComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapAddressMarkerComponent;
});