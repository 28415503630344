define('ember-screen/window/browser', ['exports', 'css-mediaquery'], function (exports, _cssMediaquery) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var _default = (function () {
    function _default() {
      _classCallCheck(this, _default);

      this.listeners = [];
      this.resizeListener = this._windowDidResize.bind(this);
      this.stubbedMediaFeatures = false;
      window.addEventListener('resize', this.resizeListener);
    }

    _createClass(_default, [{
      key: 'onSizeUpdate',
      value: function onSizeUpdate(listener) {
        // Immediately call the listener to set initial size
        listener(this.dimensions);

        this.listeners.push(listener);
      }
    }, {
      key: 'stubMediaFeatures',
      value: function stubMediaFeatures(features) {
        this.stubbedMediaFeatures = features;
        this.resizeListener();
      }
    }, {
      key: 'teardown',
      value: function teardown() {
        window.removeEventListener('resize', this.resizeListener);
      }
    }, {
      key: 'matchesMediaQuery',
      value: function matchesMediaQuery(query) {
        if (this.stubbedMediaFeatures) {
          return (0, _cssMediaquery.matchQuery)(query, this.stubbedMediaFeatures);
        } else {
          return window.matchMedia(query).matches;
        }
      }
    }, {
      key: '_windowDidResize',
      value: function _windowDidResize() {
        var _this = this;

        this.listeners.forEach(function (l) {
          return l(_this.dimensions);
        });
      }
    }, {
      key: 'dimensions',
      get: function get() {
        if (this.stubbedMediaFeatures) {
          return {
            width: this.stubbedMediaFeatures.width || window.innerWidth,
            height: this.stubbedMediaFeatures.height || window.innerHeight
          };
        } else {
          return {
            width: window.innerWidth,
            height: window.innerHeight
          };
        }
      }
    }]);

    return _default;
  })();

  exports['default'] = _default;
});