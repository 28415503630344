define('ember-screen/window/null', ['exports', 'css-mediaquery'], function (exports, _cssMediaquery) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var MOST_COMMON_SCREEN_SIZE = {
    width: 1366,
    height: 768,
    type: 'screen'
  };

  var _default = (function () {
    function _default() {
      _classCallCheck(this, _default);

      this.listeners = [];
      this.stubbedMediaFeatures = MOST_COMMON_SCREEN_SIZE;
    }

    _createClass(_default, [{
      key: 'onSizeUpdate',
      value: function onSizeUpdate(listener) {
        // Immediately call the listener to set initial size
        listener(this.dimensions);

        this.listeners.push(listener);
      }
    }, {
      key: 'stubMediaFeatures',
      value: function stubMediaFeatures(features) {
        var _this = this;

        this.stubbedMediaFeatures = features;
        this.listeners.forEach(function (l) {
          return l(_this.dimensions);
        });
      }
    }, {
      key: 'teardown',
      value: function teardown() {}
    }, {
      key: 'matchesMediaQuery',
      value: function matchesMediaQuery(query) {
        return (0, _cssMediaquery.matchQuery)(query, this.stubbedMediaFeatures);
      }
    }, {
      key: 'dimensions',
      get: function get() {
        return {
          width: this.stubbedMediaFeatures.width,
          height: this.stubbedMediaFeatures.height
        };
      }
    }]);

    return _default;
  })();

  exports['default'] = _default;
});