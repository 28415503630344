define("ember-g-map/templates/components/g-map-address-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tDB7KmGN",
    "block": "{\"symbols\":[\"markerContext\",\"&default\"],\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[4,\"g-map-marker\",[[23,[\"mapContext\"]]],[[\"lat\",\"lng\",\"viewport\",\"icon\",\"label\",\"group\",\"title\",\"onClick\",\"onDrag\"],[[23,[\"lat\"]],[23,[\"lng\"]],[23,[\"viewport\"]],[23,[\"icon\"]],[23,[\"label\"]],[23,[\"group\"]],[23,[\"title\"]],[23,[\"onClick\"]],[23,[\"onDrag\"]]]],{\"statements\":[[0,\"    \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"g-map-marker\",[[23,[\"mapContext\"]]],[[\"lat\",\"lng\",\"viewport\",\"icon\",\"label\",\"title\",\"group\",\"onClick\",\"onDrag\"],[[23,[\"lat\"]],[23,[\"lng\"]],[23,[\"viewport\"]],[23,[\"icon\"]],[23,[\"label\"]],[23,[\"title\"]],[23,[\"group\"]],[23,[\"onClick\"]],[23,[\"onDrag\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-g-map/templates/components/g-map-address-marker.hbs"
    }
  });

  _exports.default = _default;
});