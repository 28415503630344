define("ember-phone-input/instance-initializers/phone-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var config = appInstance.resolveRegistration('config:environment');
    var lazyLoad = config.phoneInput.lazyLoad;

    if (!lazyLoad) {
      appInstance.lookup('service:phone-input').load();
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});