define('ember-g-map/components/g-map-polyline-coordinate', ['exports', 'ember-g-map/templates/components/g-map-polyline-coordinate', 'ember-g-map/components/g-map-polyline'], function (exports, _gMapPolylineCoordinate, _gMapPolyline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      observer = Ember.observer,
      computed = Ember.computed,
      run = Ember.run,
      assert = Ember.assert;


  var GMapPolylineCoordinateComponent = Ember.Component.extend({
    layout: _gMapPolylineCoordinate.default,
    classNames: ['g-map-polyline-coordinate'],

    polyline: computed.alias('polylineContext.polyline'),

    init: function init() {
      this._super.apply(this, arguments);

      var polylineContext = this.get('polylineContext');
      assert('Must be inside {{#g-map-polyline}} component with context set', polylineContext instanceof _gMapPolyline.default);

      polylineContext.registerCoordinate(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (isEmpty(this.get('coordinate'))) {
        var coordinate = new google.maps.LatLng();
        this.set('coordinate', coordinate);
      }
      this.setPosition();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('polylineContext').unregisterCoordinate(this);
    },


    coordsChanged: observer('lat', 'lng', function () {
      run.once(this, 'setPosition');
    }),

    setPosition: function setPosition() {
      var polylineContext = this.get('polylineContext');
      var lat = this.get('lat');
      var lng = this.get('lng');

      if (isPresent(polylineContext) && isPresent(lat) && isPresent(lng)) {
        var coordinate = new google.maps.LatLng(lat, lng);
        this.set('coordinate', coordinate);
        polylineContext.setPath();
      }
    }
  });

  GMapPolylineCoordinateComponent.reopenClass({
    positionalParams: ['polylineContext']
  });

  exports.default = GMapPolylineCoordinateComponent;
});