define("ember-cli-sticky/components/sticky-container", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'sticky',
    topSpacing: 0,
    defaultOptions: Ember.computed('topSpacing', function () {
      return {
        topSpacing: this.get('topSpacing')
      };
    }),
    mergedOptions: Ember.computed('options', function () {
      return Ember.assign(this.get('defaultOptions'), this.get('options'));
    }),
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)(this.element).sticky(this.get('mergedOptions'));
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).unstick();
    }
  });

  _exports.default = _default;
});