define('ember-cli-cloudinary/components/cloudinary-direct-file', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    classNames: ['cloudinary-fileupload'],

    attributeBindings: ['name', 'type', 'data-cloudinary-field', 'data-form-data', 'multiple', 'style', 'accept'],

    // Attributes
    name: 'file',
    type: 'file',
    multiple: false,
    fieldName: null,
    'data-cloudinary-field': _ember['default'].computed.alias('fieldName'),
    accept: 'image/jpeg,image/gif,image/png',
    style: _ember['default'].String.htmlSafe(""),

    // Endpoint
    signatureEndpoint: null,

    // Default Options
    disableImageResize: false,
    imageMaxWidth: 10000000,
    imageMaxHeight: 10000000,
    acceptFileTypes: /(\.|\/)(gif|jpe?g|png|bmp|ico)$/i,
    maxFileSize: 50000000,
    loadImageMaxFileSize: 50000000,

    // Fetch signature
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get('signatureEndpoint')) {
        _ember['default'].Logger.error('`signatureEndpoint` parameter must be specified on cloudinary-direct-file component.');
      }

      _ember['default'].$.get(this.get('signatureEndpoint'), { timestamp: Date.now() / 1000 }).done(function (response) {
        _ember['default'].run(function () {
          _this.set('data-form-data', JSON.stringify(response));
        });
      });
    },

    didSetData: _ember['default'].observer('data-form-data', function () {
      _ember['default'].run.next(this, function () {
        this.$().cloudinary_fileupload({
          disableImageResize: this.get('disableImageResize'),
          imageMaxWidth: this.get('imageMaxWidth'),
          imageMaxHeight: this.get('imageMaxHeight'),
          acceptFileTypes: this.get('acceptFileTypes'),
          maxFileSize: this.get('maxFileSize'),
          loadImageMaxFileSize: this.get('loadImageMaxFileSize')
        });
      });
    }),

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.$().bind('cloudinarydone', function (e, data) {
        _this2.sendAction('onUploadDone', e, data);
      });

      this.$().bind('cloudinaryprogress', function (e, data) {
        _this2.sendAction('fileProgress', e, data);
      });

      this.$().bind('cloudinaryprogressall', function (e, data) {
        _this2.sendAction('allFileProgress', e, data);
      });

      this.$().bind('cloudinarystart', function (e, data) {
        _this2.sendAction('onUploadStart', e, data);
      });

      this.$().bind('cloudinarystop', function (e, data) {
        _this2.sendAction('onUploadStop', e, data);
      });

      this.$().bind('cloudinaryfail', function (e, data) {
        _this2.sendAction('onUploadFail', e, data);
      });

      this.$().bind('fileuploadprocessfail', function (e, data) {
        _this2.sendAction('onUploadFail', e, data);
      });

      this.$().bind('cloudinaryalways', function (e, data) {
        _this2.sendAction('onUploadAlways', e, data);
      });
    }
  });
});