define('ember-drag-sort/services/drag-sort', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {

    // ----- Static properties -----
    isDragging: false,
    isDraggingUp: null,

    draggedItem: null,
    group: null,

    sourceList: null,
    targetList: null,
    sourceIndex: null,
    targetIndex: null,

    lastDragEnteredList: null,

    // ----- Custom methods -----
    startDragging: function startDragging(_ref) {
      var _this = this;

      var item = _ref.item,
          index = _ref.index,
          items = _ref.items,
          group = _ref.group;

      this.setProperties({
        isDragging: true,
        isDraggingUp: false,

        draggedItem: item,
        group: group,

        sourceList: items,
        targetList: items,
        sourceIndex: index,
        targetIndex: index
      });

      if (items.length > 1) {
        if (index === 0) {
          this.set("targetIndex", index + 1);
          this.set("isDraggingUp", true);
        } else {
          this.set("targetIndex", index - 1);
        }
      }

      Ember.run.next(function () {
        _this.trigger('start', {
          group: group,
          draggedItem: item,
          sourceList: items,
          sourceIndex: index
        });
      });
    },
    draggingOver: function draggingOver(_ref2) {
      var _this2 = this;

      var group = _ref2.group,
          index = _ref2.index,
          items = _ref2.items,
          isDraggingUp = _ref2.isDraggingUp;

      // Ignore hovers over irrelevant groups
      if (group !== this.get('group')) return;

      // Ignore hovers over irrelevant lists
      if (items !== this.get('targetList')) return;

      if (index !== this.get('targetIndex')) {
        Ember.run.next(function () {
          _this2.trigger('sort', {
            group: group,
            sourceList: _this2.get('sourceList'),
            sourceIndex: _this2.get('sourceIndex'),
            draggedItem: _this2.get('draggedItem'),
            targetList: _this2.get('targetList'),
            oldTargetIndex: _this2.get('targetIndex'),
            newTargetIndex: index
          });
        });
      }

      // Remember current index and direction
      this.setProperties({
        targetIndex: index,
        isDraggingUp: isDraggingUp
      });
    },
    dragEntering: function dragEntering(_ref3) {
      var _this3 = this;

      var group = _ref3.group,
          items = _ref3.items;

      // Ignore entering irrelevant groups
      if (group !== this.get('group')) return;

      // Reset index when entering a new list
      if (items !== this.get('targetList')) {

        Ember.run.next(function () {
          _this3.trigger('move', {
            group: group,
            sourceList: _this3.get('sourceList'),
            sourceIndex: _this3.get('sourceIndex'),
            draggedItem: _this3.get('draggedItem'),
            oldTargetList: _this3.get('targetList'),
            newTargetList: items,
            targetIndex: 0
          });
        });

        this.set('targetIndex', 0);
      }

      // Remember entering a new list
      this.setProperties({
        targetList: items,
        lastDragEnteredList: items
      });
    },
    endDragging: function endDragging(_ref4) {
      var _this4 = this;

      var action = _ref4.action;

      var sourceList = this.get('sourceList');
      var sourceIndex = this.get('sourceIndex');
      var targetList = this.get('targetList');
      var targetIndex = this.get('targetIndex');
      var isDraggingUp = this.get('isDraggingUp');
      var group = this.get('group');
      var draggedItem = this.get('draggedItem');

      if (sourceList !== targetList || sourceIndex !== targetIndex) {
        // Account for dragged item shifting indexes by one
        if (sourceList === targetList && targetIndex > sourceIndex) targetIndex--;

        // Account for dragging down
        if (
        // Dragging down
        !isDraggingUp

        // Target index is not after the last item
        && targetIndex < targetList.get('length')

        // The only element in target list is not the one dragged
        && !(targetList.get('length') === 1 && targetList.get('firstObject') === draggedItem)) targetIndex++;

        if ((sourceList !== targetList || sourceIndex !== targetIndex) && typeof action === 'function') {
          Ember.run.next(function () {
            action({
              group: group,
              draggedItem: draggedItem,
              sourceList: sourceList,
              sourceIndex: sourceIndex,
              targetList: targetList,
              targetIndex: targetIndex
            });
          });
        }
      }

      this._reset();

      Ember.run.next(function () {
        _this4.trigger('end', {
          group: group,
          draggedItem: draggedItem,
          sourceList: sourceList,
          sourceIndex: sourceIndex,
          targetList: targetList,
          targetIndex: targetIndex
        });
      });
    },
    _reset: function _reset() {
      this.setProperties({
        isDragging: false,
        isDraggingUp: null,

        draggedItem: null,
        group: null,

        sourceList: null,
        targetList: null,
        sourceIndex: null,
        targetIndex: null,

        lastDragEnteredList: null
      });
    }
  });
});