define("ember-g-map/templates/components/g-map-route-address-waypoint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xJwZDq1r",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"g-map-route-waypoint\",[[23,[\"routeContext\"]]],[[\"lat\",\"lng\"],[[23,[\"lat\"]],[23,[\"lng\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-g-map/templates/components/g-map-route-address-waypoint.hbs"
    }
  });

  _exports.default = _default;
});