define('ember-cli-cloudinary/components/cloudinary-image', ['exports', 'ember'], function (exports, _ember) {

  var CloudinaryImageComponent = _ember['default'].Component.extend({
    tagName: 'img',
    attributeBindings: ['src', 'width', 'height', 'alt'],

    width: _ember['default'].computed.alias('options.width'),
    height: _ember['default'].computed.alias('options.height'),

    src: _ember['default'].computed('publicId', 'width', 'height', function () {
      return _ember['default'].$.cloudinary.url(this.get('publicId'), this.get('options'));
    })
  });

  CloudinaryImageComponent.reopenClass({
    positionalParams: ['publicId', 'options']
  });

  exports['default'] = CloudinaryImageComponent;
});