define('ember-seo-meta-tags/initializers/seo', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize(app) {}

  var get, set;

  get = _ember['default'].get;
  set = _ember['default'].set;

  // Set title and metaTags properties in any route to update page title and meta tags respectively.
  _ember['default'].Route.reopen({
    tagsData: _ember['default'].inject.service('tags-data'),
    title: null,
    metaTags: null,
    titleObserver: _ember['default'].observer('title', function () {
      var changedTitle = get(this, 'title');
      return this.setTitle(changedTitle);
    }),
    tagsObserver: _ember['default'].observer('metaTags', function () {
      var changedTags = get(this, 'metaTags');
      return this.parseTags(changedTags, false);
    }),
    setTitle: function setTitle(title) {
      window.document.title = title;
      return;
    },
    parseTags: function parseTags(tags, resetTags) {
      var that;
      that = this;
      if (!tags) {
        return;
      }
      return tags.forEach(function (tag) {
        var content, name;
        if (resetTags) {
          content = '';
        } else {
          content = get(tag, 'tagContent');
        }
        name = get(tag, 'tagName');
        type = get(tag, 'tagType');
        return that.setMetaTag(name, content, type);
      });
    },
    getTagAttribute: function getTagAttribute(name, type) {
      if (type && type.length) {
        return type;
      }

      var openGraph, swiftType;
      openGraph = /^og:/;
      swiftType = /^st:/; //https://swiftype.com/documentation/meta_tags
      if (openGraph.test(name) || swiftType.test(name)) {
        return 'property';
      }
      return 'name';
    },
    setMetaTag: function setMetaTag(name, content, type) {
      var attr;
      attr = this.getTagAttribute(name, type);
      if (_ember['default'].$('meta[' + attr + '=\'' + name + '\']').length > 0) {
        return _ember['default'].$('meta[' + attr + '=\'' + name + '\']').attr('content', content);
      } else {
        return _ember['default'].$('head').append('<meta ' + attr + '=\'' + name + '\' content=\'' + content + '\'>');
      }
    },
    initRouteData: function initRouteData(type, value) {
      var oldValue;
      oldValue = get(this, type);
      if (value) {
        set(this, type, value);
        this.notifyPropertyChange(type);
      } else if (!_ember['default'].isBlank(oldValue)) {
        // If title or metaTags are defined in route as properties, then they will be picked up from here.
        this.notifyPropertyChange(type);
      } else {
        value = get(this, 'tagsData._base.' + type);
        set(this, type, value);
      }
    },
    readServiceData: function readServiceData() {
      // Seed title and Meta tags on load
      var routeName, tagsData, seedTitle, seedMetaTags;
      routeName = get(this, 'routeName');
      tagsData = get(this, 'tagsData');

      seedTitle = null;

      if (tagsData[routeName] && tagsData[routeName].title) {
        seedTitle = tagsData[routeName].title;
      }

      seedMetaTags = get(this, 'tagsData.' + routeName + '.metaTags');
      this.initRouteData('title', seedTitle);
      this.initRouteData('metaTags', seedMetaTags);
    },
    clearTags: function clearTags() {
      var tags = get(this, 'metaTags');
      return this.parseTags(tags, true);
    },
    enter: function enter() {
      this._super();
      this.readServiceData();
    },
    exit: function exit() {
      this._super();
      this.clearTags();
    }
  });

  exports['default'] = {
    name: 'seo',
    initialize: initialize
  };
});
/*jshint esversion: 6 */