define("ember-place-autocomplete/templates/components/place-autocomplete-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oFaVqjO6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[22,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"input\",null,[[\"value\",\"tabindex\",\"disabled\",\"focus-out\",\"placeholder\",\"bubbles\",\"class\"],[[23,[\"value\"]],[23,[\"tabindex\"]],[23,[\"disabled\"]],[27,\"action\",[[22,0,[]],\"focusOut\"],null],[23,[\"placeholder\"]],true,[27,\"concat\",[[27,\"if\",[[23,[\"inputClass\"]],[27,\"-normalize-class\",[\"inputClass\",[23,[\"inputClass\"]]],null]],null],\" \"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-place-autocomplete/templates/components/place-autocomplete-field.hbs"
    }
  });

  _exports.default = _default;
});