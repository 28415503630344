define("ember-drag-sort/templates/components/drag-sort-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B5y1VUxB",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[4,\"drag-sort-item\",null,[[\"item\",\"index\",\"items\",\"group\",\"handle\",\"class\",\"tagName\",\"draggingEnabled\",\"dragEndAction\",\"determineForeignPositionAction\"],[[22,1,[]],[22,2,[]],[23,[\"items\"]],[23,[\"group\"]],[23,[\"handle\"]],[23,[\"childClass\"]],[23,[\"childTagName\"]],[23,[\"draggingEnabled\"]],[23,[\"dragEndAction\"]],[23,[\"determineForeignPositionAction\"]]]],{\"statements\":[[0,\"    \"],[14,3,[[22,1,[]],[22,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-drag-sort/templates/components/drag-sort-list.hbs"
    }
  });

  _exports.default = _default;
});