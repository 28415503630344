define('ember-screen/services/screen', ['exports', 'ember', 'ember-screen/window/browser', 'ember-screen/window/null'], function (exports, _ember, _emberScreenWindowBrowser, _emberScreenWindowNull) {

  var isFastBoot = typeof FastBoot !== 'undefined';
  var WindowClass = isFastBoot ? _emberScreenWindowNull['default'] : _emberScreenWindowBrowser['default'];

  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.win = new WindowClass();
      this.win.onSizeUpdate(this.handleResize.bind(this));
    },

    willDestroy: function willDestroy() {
      this.win.teardown();
    },

    handleResize: function handleResize(_ref) {
      var width = _ref.width;
      var height = _ref.height;

      this.set('width', width);
      this.set('height', height);
    },

    stubMediaFeatures: function stubMediaFeatures(features) {
      this.win.stubMediaFeatures(features);
    }
  });
});
/* globals FastBoot */