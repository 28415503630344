define('ember-cli-cloudinary/instance-initializers/cloudinary-config', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize(appInstance) {
    var ENV = appInstance.resolveRegistration('config:environment');

    if (!ENV.cloudinary || !ENV.cloudinary.apiKey || !ENV.cloudinary.cloudName) {
      _ember['default'].Logger.error('Please specify your cloudinary.cloudName and cloudinary.apiKey in your config.');
      return;
    }

    _ember['default'].$.cloudinary.config({
      cloud_name: ENV.cloudinary.cloudName,
      api_key: ENV.cloudinary.apiKey,
      secure: true
    });
  }

  exports['default'] = {
    name: 'cloudinary-config',
    initialize: initialize
  };
});