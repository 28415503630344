define("ember-g-map/templates/components/g-map-infowindow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dugFNXV2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h1\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[7,\"p\"],[9],[1,[21,\"message\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-g-map/templates/components/g-map-infowindow.hbs"
    }
  });

  _exports.default = _default;
});